import React from "react"
import Layout from "../../components/layout/layout"

import Seo from "../../components/layout/seo";

export default function Imprint() {
  return (
    <Layout showBottom="false">
      <Seo title="Drops | Impressum | Toothpaste Drops" path="/pages/imprint" description="Über 5000 zufriedene Kunden - Natürlich - Plastikfrei - Ohne Tierversuche - Vegan - Zahnpasta Drops. Hilf uns den Plastikmüll in unserer Umwelt & den Meeren zu beenden. #weltretter" />
      <div style={{ minHeight: 'calc(100vh - 355px)' }}>
        <div style={{ paddingTop: '100px'}} className="container mb-5">
          <h1>Impressum</h1>
          <div className="title-condition">
            <p><strong><span style={{fontSize:'16px'}}>Impressum</span></strong></p>
            <p><strong>Routinery GmbH</strong></p>
            <p>Weinbergsweg 4a<br /> 10119 Berlin<br /> Deutschland<br /> <br /> </p>
            <p>Telefon: 030-93683498</p>
            <p>Mail: info@routinery.de</p>
            <p>Gesch&auml;ftsf&uuml;hrer: Dr. Florian Böckling, Iman Sheikholmolouki</p>
            <p>Handelsregister: Amtsgericht Charlottenburg, HRB 214614</p>
            <p>Umsatzsteuer-Identifikationsnummer(n): DE328457115</p>
            <p>Verantwortlich gem&auml;&szlig; &sect; 18 MStV:<br /> Dr. Florian Böckling<br /> Weinbergsweg 4a<br /> 10119 Berlin</p>
            <p>Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit, die Sie hier finden <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noreferrer">https://ec.europa.eu/consumers/odr/</a>. Zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle sind wir nicht verpflichtet und nicht bereit.  <br /></p>
            <p><i><a target="_blank" rel="noreferrer" title="Impressum" href="https://legal.trustedshops.com/produkte/rechtstexter">Impressum</a> erstellt mit dem <a target="_blank" rel="noreferrer" title="Trusted Shops" href="https://legal.trustedshops.com/">Trusted Shops</a> Rechtstexter in Kooperation mit <a target="_blank" title="FÖHLISCH Rechtsanwälte" href="https://foehlisch.com" rel="noreferrer nofollow">FÖHLISCH Rechtsanwälte</a>.</i></p>
          </div>
        </div>
      </div>
    </Layout>
  )
}
